@tailwind base;
@tailwind components;
@tailwind utilities;

/*.targetInsideOfRadio > span > span > div > svg ~ svg > path {*/
/*  color: #2149b9;*/
/*}*/

#container {
  background-image: url('../public/images/fullBackground.png');
  background-position: bottom;
  background-size: cover;
}

.overlayBackground {
  background-color: rgba(0,0,20,0.8);
}

/*.MuiFormLabel-root {*/
/*  color: white !important;*/
/*}*/

/*.bg-white .MuiFormLabel-root, .MuiInputBase-input, svg{*/
/*  color: black !important;*/
/*}*/

/*.MuiPaper-root {*/
/*  background-color: white !important;*/
/*  color: black !important;*/
/*}*/